<template>
  <div role="alert"
    id="formErrorBlock"
    class="validation-errors-block"
  >
    <h2>There is a problem</h2>
    <a
      v-for="(error, index) in errors"
      :id="`error_${error.id}`"
      :key="index"
      class="cursor-pointer"
      type="text"
      tabindex="0"
      role="button"
      @click="scrollTo(error.id)"
      @keyup.enter="scrollTo(error.id)"
    >
      <span aria-hidden="true">{{ error.message }}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: ['errors'],
  methods: {
    scrollTo(id) {
      let label = document.querySelector(`label[for='${id}']`);
      label.scrollIntoView({ behavior: 'smooth', block: 'start' });
      let input = document.getElementById(id)
      if(input){
        input.focus({focusVisible:true});
      }
    }
  }
}
</script>
