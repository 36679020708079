<template>
  <section
    class="white-background-section pb-2"
    aria-label="Member Benefits"
  >
    <div class="container">
      <el-row>
        <div class="join-heading-text">
          <h2 class="join-title">
            {{ joinText.heading }}
          </h2>
        </div>
        <div class="join-body">
          <el-col
            class
            :md="24"
            :lg="24"
          >
            <div class="left-text mb-4">
              <h2 class="involve-title">
                {{ euPolicyText.heading }}
              </h2>
              <p class="involve-sub-text">
                {{ euPolicyText.text_1 }}
              </p>
              <div class>
                <ul class="involve-list">
                  <li
                    v-for="point in euPolicyBulletPoints_1"
                    :key="point.text"
                  >
                    {{ point.text }}
                  </li>
                </ul>
              </div>
              <p class="involve-sub-text">
                {{ euPolicyText.text_2 }}
              </p>
              <div class>
                <ul class="involve-list">
                  <li
                    v-for="point in euPolicyBulletPoints_2"
                    :key="point.text"
                  >
                    {{ point.text }}
                  </li>
                </ul>
              </div>
              <h2 class="involve-title mt-4">
                {{ euFundingText.heading }}
              </h2>
              <p class="involve-sub-text">
                {{ euFundingText.text_1 }}
              </p>
              <p class="involve-sub-text">
                {{ euFundingText.text_2 }}
              </p>
              <p class="involve-sub-text">
                {{ euFundingText.text_3 }}
              </p>
              <div class>
                <ul class="involve-list">
                  <li
                    v-for="point in euFundingBulletPoints_1"
                    :key="point.text"
                  >
                    {{ point.text }}
                  </li>
                </ul>
              </div>
              <p
                class="involve-sub-text"
                v-html="euFundingText.text_4"
              ></p>
              <!--p class="involve-sub-text" v-html="euFundingText.text_5"></!--p-->
            </div>
          </el-col>
        </div>
      </el-row>

      <el-row>
        <el-col
          class
          :md="24"
          :lg="14"
        >
          <div class="left-text pr-2">
            <h2 class="involve-title">
              {{ euAmbitionsText.heading }}
            </h2>
            <p class="involve-sub-text">
              {{ euAmbitionsText.text_1 }}
            </p>
            <p class="involve-sub-text">
              {{ euAmbitionsText.text_2 }}
            </p>
            <p class="involve-sub-text">
              {{ euAmbitionsText.text_3 }}
            </p>
            <div class>
              <ul class="involve-list">
                <li
                  v-for="point in euAmbitionsBulletPoints_1"
                  :key="point.text"
                >
                  {{ point.text }}
                </li>
              </ul>
            </div>
          </div>
        </el-col>

        <el-col
          :md="24"
          :lg="10"
        >
          <div class="invlove-img mt-5 scotland-in-europe-image">
            <img
              alt="scotland In Europe"
              src="../../../../static/img/scotland-in-europe.png"
            />
          </div>
        </el-col>
      </el-row>

      <el-row>
        <div class="join-body">
          <el-col
            class
            :md="24"
            :lg="24"
          >
            <div class="left-text">
              <h2 class="involve-title">
                {{ euContactsText.heading }}
              </h2>
              <p class="involve-sub-text">
                {{ euContactsText.text_1 }}
              </p>
              <div class>
                <ul class="involve-list">
                  <li
                    v-for="point in euContactsBulletPoints_1"
                    :key="point.text"
                  >
                    {{ point.text }}
                  </li>
                </ul>
              </div>
              <p class="involve-sub-text">
                {{ euContactsText.text_2 }}
              </p>
              <div class>
                <ul class="involve-list">
                  <li
                    v-for="point in euContactsBulletPoints_2"
                    :key="point.text"
                  >
                    <span v-html="point.text"></span>
                  </li>
                </ul>
              </div>
              <h2 class="involve-title mt-4">
                {{ euJoiningText.heading }}
              </h2>
              <p class="involve-sub-text">
                {{ euJoiningText.text_1 }}
              </p>
              <p class="involve-sub-text">
                {{ euJoiningText.text_2 }}
              </p>
              <div class>
                <ul class="involve-list">
                  <li
                    v-for="point in euJoiningBulletPoints_1"
                    :key="point.text"
                  >
                    {{ point.text }}
                  </li>
                </ul>
              </div>
            </div>
          </el-col>
        </div>
      </el-row>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      joinText: {
        heading: "Benefits and services you'll have access to as a member",
      },
      euPolicyText: {
        heading: "Updates and analysis of relevant EU policy developments",
        text_1:
          "Policy decisions made by the European Union are relevant to Scotland's public authorities, businesses and individuals. As a Scotland Europa member, we can help you to navigate and understand updates at EU-level, as well as highlight opportunities for collaboration and partnership. More specifically, we can offer you and your business:",
        text_2:
          "When interpreting policies and providing updates, we'll focus on the policy areas that are most important to our members and to Scotland's interests. Our priority areas, as defined by our members and Scottish Enterprise, are:",
      },
      euPolicyBulletPoints_1: [
        {
          text: "Crucial analysis of the wider impact of EU-level decisions on the public and private sectors",
        },
        { text: "Insight and expertise on EU public affairs" },
        {
          text: "Early notification and interpretation of European issues affecting Scottish interests",
        },
        {
          text: "Regular briefings on relevant legislative negotiations, public consultations, hearings and conferences",
        },
        {
          text: "A voice in EU policy negotiations and discussions",
        },
      ],
      euPolicyBulletPoints_2: [
        {
          text: "Enterprise",
        },
        { text: "Net Zero, Energy, Climate, and the Environment" },
        {
          text: "Regional Innovation",
        },
        {
          text: "Research and Innovation",
        },
        {
          text: "Financial Services",
        },
        {
          text: "International Economy and Trade",
        },
        {
          text: "People and Place",
        },
        {
          text: "Just Transition",
        },
        {
          text: "Cultural Heritage and Creativity",
        },
        {
          text: "EU Institutional Developments",
        },
      ],
      euFundingText: {
        heading: "EU funding and project support",
        text_1:
          "European Union funding plays an important role in supporting sustainable economic growth in Scotland. European projects can make a real difference to the work of Scottish organisations, the competitiveness of Scottish businesses and the lives of Scottish citizens.",
        text_2:
          "Scotland Europa helps members to understand the funding programmes, identify opportunities and develop projects for European funding.",
        text_3: "As a member, you'll have access to:",
        text_4: `The main fund we currently offer support for is <a href='https://portal.funding-portal.scot/funding-programmes/horizon-europe' target='_blank'>Horizon Europe <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>. We update members accordingly with developments in this domain following the UK's withdrawal from the EU.`,
        /*text_5:`Explore other available European funding options on the <a href='https://funding-portal.scot/' target='_blank'>Scottish Funding Portal <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>.`*/
      },
      euFundingBulletPoints_1: [
        {
          text: "Regular updates on funding programmes and related policy developments",
        },
        {
          text: "Information, events, and workshops",
        },
        {
          text: "Help finding transnational partners",
        },
        {
          text: "Liaison with UK national contact points",
        },
        {
          text: "Extensive support throughout your project lifecycle",
        },
        {
          text: "Advice on specific compliance issues, prior to the submission of claims",
        },
        {
          text: "Help reviewing your ideas, advice on developing your project, and advice on submitting your application",
        },
      ],
      euAmbitionsText: {
        heading: "Learning opportunities and help achieving your EU ambitions",
        text_1:
          "Learning and capacity-building is an integral part of our role as a unique enabler of Scottish EU engagement. Building greater capacity within our member organisations to achieve their EU engagement ambitions has always been a core element of our existing membership services.",
        text_2:
          "As EU opportunities become broader and more complex, building capacity of a wider range of colleagues is needed to provide the expertise and resource required to access these.",
        text_3: "As a member, you'll have access to:",
      },
      euAmbitionsBulletPoints_1: [
        {
          text: "General EU familiarisation training in Scotland and Brussels",
        },
        {
          text: "More specific capacity building programmes involving tailored meeting programmes with key decision-makers for specialist and senior staff",
        },
        {
          text: "The EU Insight Familiarisation programme - an annual, two-day Brussels based programme open to members and non-members seeking to develop their understanding of the EU institutions, process, policies and funding programmes",
        },
        {
          text: "Opportunities to meet officials from the in and around the EU institutions, as well as other stakeholders in Brussels",
        },
      ],
      euContactsText: {
        heading: "Access to our extensive networks and contacts in the EU",
        text_1:
          "With a proven record of developing Scotland's interests and maximising our opportunities in Europe, we have an extensive network of contacts across Europe. As a member, you'll have access to:",
        text_2:
          "Scotland Europa is a member of, or cooperates closely with, a number of European-level networks and organisations to further the interests of Scotland and our members. These networks include:",
      },
      euContactsBulletPoints_1: [
        {
          text: "Our extensive network of contacts across a variety of organisations within Europe",
        },
        {
          text: "Opportunities to build strategic alliances and ongoing relationships for policy influencing, developing projects and promotional activities",
        },
        {
          text: "Opportunities to develop partnerships and projects for upcoming calls for proposals",
        },
        {
          text: "Policy seminars, project forums and promotional/networking events in our Scotland House conference centre. These discussions with the EU institutions, representatives of EU regions and nations, EU networks and other stakeholders ensure that your interests stay well connected in Europe",
        },
        {
          text: "Free use of the conference centre, meeting rooms and hotdesking facilities in Scotland House",
        },
        {
          text: "Event development and management support if you're holding events in Brussels",
        },
      ],
      euContactsBulletPoints_2: [
        {
          text: `Economic development networks such as the <a href='http://www.errinnetwork.eu/' target='_blank'>European Regions Research and Innovation Network (ERRIN) <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a> and the <a href='http://www.eurada.org/' target='_blank'>European Association of Development Agencies (Eurada) <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: `Think tanks such as the <a href='https://www.epc.eu/en' target='_blank'>European Policy Centre (EPC) <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a> and the Centre for <a href='http://www.ceps.be/' target='_blank'>European Policy Studies (CEPS) <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>`,
        },
        {
          text: "Key industry and sector networks covering sectors like health, tourism, finance and renewable energy",
        },
        {
          text: "Environment networks, covering themes like recycling and resource management, environment protection, nature conservation, knowledge sharing and island networks",
        },
        {
          text: `Innovation and research networks, mostly via our university members. This includes networks like the <a href='http://www.eua.be/' target='_blank'>European University Association <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>, the <a href='http://www.leru.org/' target='_blank'>League of European Research Universities (LERU) <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>  and the <a href='http://www.the-guild.eu/' target='_blank'>Guild of European Research-Intensive Universities <span class="sr-only">this link will open in a new window</span><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" class="ml-1">
                <path d="M6.5 3.69189H0.5V15.6919H12.5V9.72217" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M3.5 12.707L15.5 0.707031" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M9.5 0.707031H15.5V6.70703" stroke="#A8308C" stroke-linecap="round" stroke-linejoin="round"/>
                </svg></a>.`,
        },
      ],
      euJoiningText: {
        heading: "Who you'll be joining",
        text_1:
          "With members drawn from Scottish organisations in the public, private, academic and third sectors, Scotland Europa's partnership with European institutions gives us an influential voice and distinct insight into European affairs.",
        text_2: "Our existing members are:",
      },
      euJoiningBulletPoints_1: [
        {
          text: "Brodies",
        },
        {
          text: "Edinburgh Napier University",
        },
        {
          text: "Environmental Standards Scotland",
        },
        {
          text: "Glasgow Caledonian University",
        },
        {
          text: "Highlands and Islands Enterprise",
        },
        {
          text: "Historic Environment Scotland",
        },
        {
          text: "James Hutton Institute",
        },
        {
          text: "NatureScot",
        },
        {
          text: "Scotch Whisky Association",
        },
        {
          text: "Scottish Development International",
        },
        {
          text: "Scottish Enterprise",
        },
        {
          text: "Scottish Environment Protection Agency",
        },
        {
          text: "Scottish Financial Enterprise",
        },
        {
          text: "Scottish Funding Council",
        },
        {
          text: "Scottish Government Digital Health and Care Directorate",
        },
        {
          text: "Scottish Natural Heritage",
        },
        {
          text: "University of Aberdeen",
        },
        {
          text: "University of Edinburgh",
        },
        {
          text: "University of Glasgow",
        },
        {
          text: "University of St Andrews",
        },
        {
          text: "University of Stirling",
        },
        {
          text: "University of Strathclyde",
        },
        {
          text: "University of the Highlands and Islands",
        },
        {
          text: "University of the West of Scotland",
        },
        {
          text: "VisitScotland",
        },
        {
          text: "Zero Waste Scotland",
        },
        {
          text: "Scottish Rural College",
        },
        {
          text: "University of Dundee",
        },
        {
          text: "Aberdeen City Council",
        }
      ],
    };
  },
};
</script>
<style lang="scss">
.join-body h2 {
  font-size: $font-size-30;
  line-height: $line-height-42;
  font-weight: 420;
  margin-bottom: 30px;
  color: black;
}
.join-body p {
  font-weight: 390;
  font-size: $font-size-19;
  line-height: $line-height-28;
}
.join-link {
  margin-top: 30px;
}
.join-experts,
.join-organisations {
  margin: 50px 0 50px 0;
}
.join-heading-text {
  margin-top: 50px;
}
</style>
