<template>
  <footer
    class="footer-section"
    aria-label="footer"
    role="contentinfo"
  >
    <div class="purple-bar">
      <div class="container">
        <el-row>
          <el-col
            :sm="24"
            class="left-feed-back-link"
          >
            <a
              href="https://www.surveymonkey.co.uk/r/N3P6BRG"
              target="_blank"
            >
              Give feedback about this website <span class="sr-only">this link will open in a new window</span>
              <svg-external-white class="external-link"></svg-external-white>
            </a>
          </el-col>
          <!--el-col :sm="24" :md="12" class="right-feed-back-link">
            <a href="https://www.surveymonkey.co.uk/r/N3P6BRG" target="_blank">Is there something wrong with this page?</a>
          </!--el-col-->
        </el-row>
      </div>
    </div>
    <div class="container footer-inner">
      <el-row>
        <el-col
          :sm="24"
          :md="12"
        >
          <nav aria-label="Other policy and help pages">
            <ul  role="menubar" class="footer-links">
              <li>
                <router-link :to="{ name: 'About' }">
                  About Scotland Europa
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Membership' }">
                  About membership
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'Signup Select Type' }">
                  Join Scotland Europa
                </router-link>
              </li>
            <!--li>
              <a target="_blank" rel="noopener noreferer" href="http://support.scotlandeuropa.com/">FAQs</a>
            </!--li-->
            </ul>
          </nav>
        </el-col>
        <el-col
          :sm="24"
          :md="12"
        >
          <nav
            class="social-icons"
            aria-label="Scotland Europa social media channels"
          >
            <!--a aria-label="facebook icon" href="https://www.facebook.com/scottishenterprise" target="_blank">
              <svg-facebook class="social-icon" title="Facebook icon"></svg-facebook>
            </!a-->
            <a
              aria-label="linkedin icon"
              href="https://www.linkedin.com/company/8304561/"
              target="_blank"
            >
              <span class="sr-only">this link will open in a new window</span><svg-linkedin
                class="social-icon"
                title="Linkedin icon"
              ></svg-linkedin>
            </a>
            <a
              aria-label="twitter icon"
              href="https://twitter.com/scotlandeuropa"
              target="_blank"
            >
              <span class="sr-only">this link will open in a new window</span><svg-twitter
                class="social-icon"
                title="Twitter icon"
              ></svg-twitter>
            </a>
          </nav>
        </el-col>

        <el-col :sm="24">
          <nav 
            class="horizontal-links" 
            aria-label="Scotland Europa legal documents"
          >
            <router-link :to="{ name: 'Accessibility' }">
              Accessibility
            </router-link>
            <router-link :to="{ name: 'Cookie Policy' }">
              Cookies
            </router-link>
            <router-link :to="{ name: 'Privacy Policy' }">
              Privacy
            </router-link>
            <router-link :to="{ name: 'Terms & Conditions' }">
              Terms
            </router-link>
            <router-link :to="{ name: 'Site Map' }">
              Sitemap
            </router-link>
          </nav>

          <div class="d-flex se-logos">
            <a
              href="https://www.scottish-enterprise.com/"
              target="_blank"
            >
              <img
                src="../../static/img/new-se-logo.png"
                class="se-logo"
                alt="Scottish Enterprise Logo"
              /><span class="sr-only">this link will open in a new window</span>
            </a>
            <!--a href="https://www.sdi.co.uk/" target="_blank">
              <img
                src="../../static/img/scottish-development-logo.png"
                class="sd-logo"
                alt="Scottish Development International Logo"
              />
            </!a-->
          </div>
        </el-col>
      </el-row>
    </div>
  </footer>
</template>

<script>
import svgLinkedin from "@/components/assets/svg-linkedin";
import svgTwitter from "@/components/assets/svg-twitter";
import svgExternalWhite from "@/components/assets/svg-link";

export default {
  components: {
    "svg-linkedin": svgLinkedin,
    "svg-twitter": svgTwitter,
    "svg-external-white": svgExternalWhite,
  }
};
</script>
